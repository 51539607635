import React from 'react'
import { graphql } from 'gatsby'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import TextContainer from '../components/TextContainer'
import PageTitle from '../components/PageTitle'
import PageBody from '../components/PageBody'
import CardList from '../components/CardList'
import FlexBoxComponent from '../components/FlexBox'
import SEO from '../components/SEO'
import CardHandwerk from '../components/CardHandwerk'

const Kontakt = ({ data }) => {
  const postNode = {
    title: `${data.contentfulPage.title} - ${config.siteTitle}`,
  }

  const { body, title, subtitle, headerImage } = data.contentfulPage

  const mappedHandwerk = data.allContentfulPage.edges.map((el, i) => (
    <CardHandwerk
      columns={'two-columns'}
      key={`handwerk-card-${i}`}
      link={el.node.slug}
      title={el.node.title}
      image={el.node.previewImage || el.node.headerImage}
      padded
    />
  ))

  return (
    <Layout>

      <SEO postNode={postNode} pagePath="contact" customTitle />

      <PageTitle
        background={headerImage}
      />

      <Container>
        <TextContainer>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {body &&
          <PageBody body={body} />
          }
        </TextContainer>
      </Container>

      <FlexBoxComponent background={'transparent'} noPaddingTop>
        <CardList columns={'four'}>
          {mappedHandwerk}
        </CardList>
      </FlexBoxComponent>

    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulPage(
      filter: {category: {eq: "Handwerk"}}
      sort: { order: ASC, fields: order }
    ) {
      edges {
        node {
          id
          category
          subtitle
          title
          slug
          headerImage {
            id
            fluid(
              quality: 100
              resizingBehavior: NO_CHANGE
              toFormat: NO_CHANGE
            ) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
          previewImage {
            id
            fluid(
              quality: 100
              resizingBehavior: NO_CHANGE
              toFormat: NO_CHANGE
            ) {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              aspectRatio
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    contentfulPage(slug: { eq: "handwerk" }) {
      id
      title
      subtitle
      headerImage {
        id
        fluid(quality: 100, maxWidth: 3840, resizingBehavior: NO_CHANGE, toFormat: NO_CHANGE) {
          srcWebp
          srcSetWebp
          srcSet
          src
          sizes
          aspectRatio
        }
      }
      body {
        json
      }
    }
  }
`

export default Kontakt
