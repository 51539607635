import React from 'react'
import styled from 'styled-components'
import Container from './Container'

import 'semantic-ui-css/components/icon.css'
import 'semantic-ui-css/components/button.css'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-flow: row wrap;
  padding: 2rem 0;
  &.no-padding-top {
    padding-top: 0;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    padding: 0.5rem 0 0 0;
  }
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  width: 100%;
  background: transparent;
  &.green {
    background: ${props => props.theme.colors.highlight};
    color: ${props => props.theme.colors.inverted};
    // border-top: 0.25rem solid ${props => props.theme.colors.lightGrey};
    border-bottom: 0.25rem solid ${props => props.theme.colors.secondary};
  }
  &.light-grey,
  &.light-grey h2 {
    background: ${props => props.theme.colors.lightGrey};
    color: ${props => props.theme.colors.secondary};
  }
  &.dark-grey h2 {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.inverted};
  }
  &.border-bottom {
    border-bottom: 0.25rem solid ${props => props.theme.colors.secondary};
  }
  &.border-top {
    border-top: 0.25rem solid ${props => props.theme.colors.secondary};
  }
`

const List = styled.ul`
  max-width: ${props => props.theme.sizes.maxWidth};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  // border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1rem 2rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    padding: 2rem 3rem;
  }
  margin: 0 auto;
`

const StyledButton = styled.button`
  &.circular.ui.button {
    background: ${props => props.theme.colors.highlight};
  }
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  h4 {
    font-size: 1.1em;
    font-family: ${props => props.theme.fonts.title};
    font-weight: 800;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.highlight};
  }
  p {
    margin-bottom: 1rem;
    font-weight: 400;
    color: ${props => props.theme.colors.inverted};
  }
  a,
  a span {
    font-weight: 400;
    transition: all 0.2s;
    display: block;
    line-height: 1.15;
    border: none;
    &.inline {
      font-weight: 700;
      display: inline;
    }
    color: ${props => props.theme.colors.inverted};
    &:visited {
      color: ${props => props.theme.colors.inverted};
    }
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }
`

const ColourOverlay = styled.div`
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  
  &.right {
    left: 50%;
  }
  
  &.grey {
    background: ${props => props.theme.colors.secondary};
    @media screen and (max-width: 800px) {
      top: 50%;
      height: 50%;
      width: 100%;
      left: 0;
      background: ${props => props.theme.colors.highlight};
      display: none;
    }
  }
  &.green {
    background: ${props => props.theme.colors.highlight};
    @media screen and (max-width: 800px) {
      height: 100%;
      top: 0;
      width: 100%;
    }
  }
`

const FlexBoxComponent = (props) => (
  <Wrapper className={`${props.background} ${props.borderBottom ? 'border-bottom' : ''} ${props.borderTop ? 'border-top' : ''}`}>
    {props.backgroundLeft &&
    <ColourOverlay className={`left ${props.backgroundLeft}`} />
    }
    {props.backgroundRight &&
    <ColourOverlay className={`right ${props.backgroundRight}`} />
    }
    <Container noPaddingTop={props.noPaddingTop}>
      {props.children}
    </Container>
  </Wrapper>
)

export default FlexBoxComponent
