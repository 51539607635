import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const CardHandwerk = props => {
  const Post = styled.li`
    position: relative;
    border-bottom: 0px solid ${props => props.theme.colors.primary};
    border-radius: 0;
    width: 100%;
    padding: 0.25rem;
    transition: background 0.2s;
    margin: 0 0 0.5rem 0;
    border-bottom: 1px solid ${props => props.theme.colors.highlight};
    flex: 0 0 49%;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      border-bottom: none;
      flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
      margin: 0 0 0.5rem 0;
    }
    @media screen and (min-width: ${props => props.theme.responsive.medium}) {
      flex: ${props => (props.featured ? '0 0 100%' : '0 0 22%')};
      &.three-columns {
        flex: ${props => (props.featured ? '0 0 100%' : '0 0 30%')};
      }
    }
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
    &:hover {
      background: rgba(255, 255, 255, 0.8);
      -webkit-animation: shadow-drop-2-center 0.4s
        cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
    }
    a {
      height: 100%;
      width: 100%;
      display: flex;
      flex-flow: column;
      color: ${props => props.theme.colors.base};
      text-decoration: none;
      hyphens: auto;
      .gatsby-image-wrapper {
        height: 0;
        padding-bottom: 100%;
        @media screen and (min-width: ${props =>
            props.theme.responsive.small}) {
          padding-bottom: 100%;
        }
      }
    }
  `

  const Title = styled.h2`
    color: ${props => props.theme.colors.secondary};
    font-size: 1.1em;
    font-family: ${props => props.theme.fonts.title};
    padding: 0.6rem 0.4rem 0.4rem 0.4rem;
    font-weight: 800;
    line-height: 1.15;
    text-align: left;
  `

  return (
    <Post className={props.columns}>
      <Link activeClassName="active" to={`/${props.link.replace('-', '/')}`}>
        <Img loading={'eager'} fluid={props.image.fluid} backgroundColor="#eeeeee"  />
        <Title>{props.title}</Title>
      </Link>
    </Post>
  )
}

export default CardHandwerk
